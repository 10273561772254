const Money = {
    methods: {
        formatMoney(number, decPlaces, decSep, thouSep = '.') {
            if (typeof number !== 'undefined' && number)
                return number.toLocaleString('pt-BR', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                });
            return 0
        },
    }
}

export default Money